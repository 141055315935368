@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&display=swap");
/** COLORS **/
/** COLORS END **/
/** FONTS **/
/** FONTS END **/
/** BACKGROUNDS **/
/** BACKGROUNDS END **/
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document ========================================================================== */
/** 1. Correct the line height in all browsers. 2. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS. */
html { line-height: 1.15; /* 1 */ -ms-text-size-adjust: 100%; /* 2 */ -webkit-text-size-adjust: 100%; /* 2 */ }

/* Sections ========================================================================== */
/** Remove the margin in all browsers (opinionated). */
body { margin: 0; }

/** Add the correct display in IE 9-. */
article, aside, footer, header, nav, section { display: block; }

/** Correct the font size and margin on `h1` elements within `section` and `article` contexts in Chrome, Firefox, and Safari. */
h1 { font-size: 2em; margin: 0.67em 0; }

/* Grouping content ========================================================================== */
/** Add the correct display in IE 9-. */
figcaption, figure { display: block; }

/** Add the correct margin in IE 8. */
figure { margin: 1em 40px; }

/** 1. Add the correct box sizing in Firefox. 2. Show the overflow in Edge and IE. */
hr { box-sizing: content-box; /* 1 */ height: 0; /* 1 */ overflow: visible; /* 2 */ }

/** Add the correct display in IE. */
main { display: block; }

/** 1. Correct the inheritance and scaling of font size in all browsers. 2. Correct the odd `em` font sizing in all browsers. */
pre { font-family: monospace, monospace; /* 1 */ font-size: 1em; /* 2 */ }

/* Links ========================================================================== */
/** 1. Remove the gray background on active links in IE 10. 2. Remove gaps in links underline in iOS 8+ and Safari 8+. */
a { background-color: transparent; /* 1 */ -webkit-text-decoration-skip: objects; /* 2 */ }

/* Text-level semantics ========================================================================== */
/** 1. Remove the bottom border in Chrome 57- and Firefox 39-. 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari. */
abbr[title] { border-bottom: none; /* 1 */ text-decoration: underline; /* 2 */ text-decoration: underline dotted; /* 2 */ }

/** Prevent the duplicate application of `bolder` by the next rule in Safari 6. */
b, strong { font-weight: inherit; }

/** Add the correct font weight in Chrome, Edge, and Safari. */
b, strong { font-weight: bolder; }

/** 1. Correct the inheritance and scaling of font size in all browsers. 2. Correct the odd `em` font sizing in all browsers. */
code, kbd, samp { font-family: monospace, monospace; /* 1 */ font-size: 1em; /* 2 */ }

/** Add the correct font style in Android 4.3-. */
dfn { font-style: italic; }

/** Add the correct background and color in IE 9-. */
mark { background-color: #ff0; color: #000; }

/** Add the correct font size in all browsers. */
small { font-size: 80%; }

/** Prevent `sub` and `sup` elements from affecting the line height in all browsers. */
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }

sub { bottom: -0.25em; }

sup { top: -0.5em; }

/* Embedded content ========================================================================== */
/** Add the correct display in IE 9-. */
audio, video { display: inline-block; }

/** Add the correct display in iOS 4-7. */
audio:not([controls]) { display: none; height: 0; }

/** Remove the border on images inside links in IE 10-. */
img { border-style: none; }

/** Hide the overflow in IE. */
svg:not(:root) { overflow: hidden; }

/* Forms ========================================================================== */
/** 1. Change the font styles in all browsers (opinionated). 2. Remove the margin in Firefox and Safari. */
button, input, optgroup, select, textarea { font-family: sans-serif; /* 1 */ font-size: 100%; /* 1 */ line-height: 1.15; /* 1 */ margin: 0; /* 2 */ }

/** Show the overflow in IE. */
button { overflow: visible; }

/** Remove the inheritance of text transform in Edge, Firefox, and IE. 1. Remove the inheritance of text transform in Firefox. */
button, select { /* 1 */ text-transform: none; }

/** 1. Prevent a WebKit bug where (2) destroys native `audio` and `video` controls in Android 4. 2. Correct the inability to style clickable types in iOS and Safari. */
button, html [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; /* 2 */ }

button, [type="button"], [type="reset"], [type="submit"] { /** Remove the inner border and padding in Firefox. */ /** Restore the focus styles unset by the previous rule. */ }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { border-style: none; padding: 0; }

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring { outline: 1px dotted ButtonText; }

/** Show the overflow in Edge. */
input { overflow: visible; }

/** 1. Add the correct box sizing in IE 10-. 2. Remove the padding in IE 10-. */
[type="checkbox"], [type="radio"] { box-sizing: border-box; /* 1 */ padding: 0; /* 2 */ }

/** Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

/** 1. Correct the odd appearance in Chrome and Safari. 2. Correct the outline style in Safari. */
[type="search"] { -webkit-appearance: textfield; /* 1 */ outline-offset: -2px; /* 2 */ /** Remove the inner padding and cancel buttons in Chrome and Safari on macOS. */ }

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

/** 1. Correct the inability to style clickable types in iOS and Safari. 2. Change font properties to `inherit` in Safari. */
::-webkit-file-upload-button { -webkit-appearance: button; /* 1 */ font: inherit; /* 2 */ }

/** Correct the padding in Firefox. */
fieldset { padding: 0.35em 0.75em 0.625em; }

/** 1. Correct the text wrapping in Edge and IE. 2. Correct the color inheritance from `fieldset` elements in IE. 3. Remove the padding so developers are not caught out when they zero out `fieldset` elements in all browsers. */
legend { box-sizing: border-box; /* 1 */ display: table; /* 1 */ max-width: 100%; /* 1 */ padding: 0; /* 3 */ color: inherit; /* 2 */ white-space: normal; /* 1 */ }

/** 1. Add the correct display in IE 9-. 2. Add the correct vertical alignment in Chrome, Firefox, and Opera. */
progress { display: inline-block; /* 1 */ vertical-align: baseline; /* 2 */ }

/** Remove the default vertical scrollbar in IE. */
textarea { overflow: auto; }

/* Interactive ========================================================================== */
/* Add the correct display in Edge, IE, and Firefox. */
details { display: block; }

/* Add the correct display in all browsers. */
summary { display: list-item; }

/* Add the correct display in IE 9-. */
menu { display: block; }

/* Scripting ========================================================================== */
/** Add the correct display in IE 9-. */
canvas { display: inline-block; }

/** Add the correct display in IE. */
template { display: none; }

/* Hidden ========================================================================== */
/** Add the correct display in IE 10-. */
[hidden] { display: none; }

html, body { font-family: "Roboto Dark", sans-serif; font-size: 16px; font-weight: 300; color: #ffffff; background-color: #000000; margin: 0; padding: 0; overflow-x: hidden; }

html::-webkit-scrollbar, body::-webkit-scrollbar { width: 8px; }

html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb { background-color: #ee751a; border-radius: 8px; }

a { text-decoration: none; color: #000000; }

h1, h2, h3, h4, h5 { margin: 0; padding: 0; }

p { margin: 0; padding: 0; }

ul { margin: 0; padding: 0; }

.container { max-width: 1920px; padding: 0 16px; margin: auto; }

.btn { display: flex; align-items: center; justify-content: center; width: 100%; max-width: 304px; height: 48px; color: #ffffff; outline: none; border: none; }

.btn:hover { opacity: 0.8; cursor: pointer; }

.flex { display: flex; }

.flex_justify--center { justify-content: center; }

.flex_align--center { align-items: center; }

/** FORM **/
.form__title { font-size: 32px; margin: 0; }

.form__body { margin-top: 32px; }

.form__group { position: relative; margin: 32px 0; }

.form__input, .form__select { position: relative; box-sizing: border-box; width: 100%; height: 48px; font-size: 16px; padding: 0 16px; outline: none; border: 1px solid #d6d8e0; border-radius: 2px; }

.form__textarea { box-sizing: border-box; width: 100%; height: 128px; font-size: 16px; border: 1px solid #d6d8e0; border-radius: 2px; padding: 16px; resize: none; }

.form__textarea:focus { outline: none; }

.form__label { display: block; color: #000000; margin-bottom: 8px; }

.form__errors { font-size: 12px; color: #d9867c; margin: 8px 0 0 0; }

.form__error { display: none; }

.form__error_type--validation { display: block; font-size: 16px; }

.form__button { margin: 40px 0 0 0; }

/** FORM END **/
/** FORM RESULT **/
.form-result { display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; }

.form-result:before { content: ''; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #707070; opacity: 0.5; }

.form-result__content { position: absolute; top: 0; right: 0; bottom: 0; left: 0; max-width: 400px; max-height: 239px; background-color: #ffffff; color: #000000; margin: auto; border-radius: 8px; }

.form-result__content:before { content: ''; position: absolute; top: -1%; left: -1%; width: 101%; height: 101%; border: 2px solid #ffffff; border-radius: 8px; }

.form-result__close { position: absolute; right: 16px; top: 8px; width: 24px; height: 24px; opacity: 0.3; }

.form-result__close:hover { opacity: 1; cursor: pointer; }

.form-result__close:before, .form-result__close:after { position: absolute; left: 15px; content: ' '; height: 33px; width: 2px; background-color: #000000; }

.form-result__close:before { transform: rotate(45deg); }

.form-result__close:after { transform: rotate(-45deg); }

.form-result__status { font-size: 20px; color: #000000; margin: 16px 0 0 16px; }

.form-result__text { text-align: center; font-size: 18px; margin: 56px 16px 0; }

/** FORM RESULT END **/
/** CHECKBOX LABEL **/
.checkbox-label__input { position: absolute; margin: 0; z-index: -1; opacity: 0; }

.checkbox-label__input:checked + .checkbox-label__link:before { background: #5f90b8; border-color: #5f90b8; }

.checkbox-label__link { position: relative; display: flex; align-items: center; height: 24px; padding: 0 0 0 30px; cursor: pointer; }

.checkbox-label__link:before { content: ''; position: absolute; top: 0; left: 0; width: 22px; height: 22px; background: #ffffff; border: 1px solid #dcdad5; border-radius: 2px; }

.checkbox-label__link:after { content: ''; position: absolute; top: 6px; left: 6px; height: 6px; width: 11px; border-top: 2px solid #ffffff; border-right: 2px solid #ffffff; transform: rotate(135deg); transition: all 0.2s ease-out 0s; opacity: 1; }

/** CHECKBOX LABEL END **/
/** FORM-RATICIPANT **/
.form-participant { position: relative; width: 100%; max-width: 800px; background: #ffffff; margin: 144px auto; padding: 40px; border-radius: 8px; box-shadow: 0 0 20px 2px #ffffff; }

.form-participant.no-m { margin: 0; }

.form-participant:before { content: ''; position: absolute; top: -1%; left: -1%; width: 101%; height: 101%; border: 2px solid #ffffff; border-radius: 8px; }

.form-participant__title { color: #000000; }

.form-participant__btn { position: relative; background-color: #000000; }

.password-form input { position: relative; box-sizing: border-box; width: 100%; height: 48px; font-size: 16px; padding: 0 16px; outline: none; border: 1px solid #d6d8e0; border-radius: 2px; }

.password-form button { width: 100%; height: 48px; margin-top: 24px; }

/** FORM-RATICIPANT END **/
/** CONTEST-OPTIONS **/
.contest-options { display: none; }

.contest-options__hidden { display: none; }

/** CONTEST-OPTIONS END **/
/** REGISTRATION-SECTION **/
.registration-section { display: flex; justify-content: center; background: center/cover url("../images/bg1.png"); min-height: 100vh; }

.registration-section .logo { display: flex; justify-content: center; padding: 40px; }

.registration-section .logo img { max-width: 600px; width: 100%; height: 100%; object-fit: contain; }

/** REGISTRATION-SECTION END **/
/** CONTEST-INFO-SECTION **/
.contest-info-section { background: center/cover url("../images/bg1.png"); min-height: 100vh; }

/** CONTEST-INFO-SECTION END **/
/** PARTICIPANTS **/
.participants .logo { display: flex; justify-content: center; padding: 40px 0; }

.participants .logo img { max-width: 600px; width: 100%; height: 100%; object-fit: contain; }

.participants .participant { display: grid; grid-template-columns: 24px 1fr; grid-gap: 16px; }

.participants .container { display: flex; justify-content: center; min-height: 100vh; }

.participants__content { width: 100%; max-width: 800px; height: 464px; background-color: #ffffff; border-radius: 8px; box-shadow: 0 0 20px 2px #fff; padding: 40px; z-index: 10; }

.participants__form-content { display: flex; justify-content: center; align-items: flex-start; max-width: 600px; }

.participants__title { font-size: 40px; color: #000000; margin-bottom: 32px; }

.participants__info { color: #000000; min-height: 344px; max-height: 344px; overflow-y: scroll; }

.participants__info::-webkit-scrollbar { width: 8px; }

.participants__info::-webkit-scrollbar-thumb { background-color: #000000; border-radius: 8px; }

.participants__item { font-size: 23px; margin-bottom: 16px; }

.participants__empty { font-size: 28px; text-transform: uppercase; }

@media (max-width: 1024px) { .contest-info-section { background: none; }
  .participants { padding: 16px; }
  .participants .logo { display: flex; justify-content: center; padding: 24px 0; margin: 0 24px; }
  .participants__content { position: relative; top: auto; left: auto; max-width: 100%; padding: 24px; }
  .participants__title { font-size: 32px; text-align: center; } }

/** PARTICIPANTS END **/
/** CONTEST **/
.contest { position: absolute; width: 100%; height: 100%; }

.contest__content { position: absolute; right: 56px; bottom: 56px; top: auto; max-width: 800px; background-color: #ffffff; border-radius: 8px; box-shadow: 0 0 20px 2px #fff; padding: 40px; }

.contest__title { color: #000000; margin-bottom: 32px; }

.contest__button { margin-top: 32px; }

.contest__btn { display: none; background-color: #ee751a; border-radius: 4px; }

/** CONTEST END **/
/** COUNTDOWN **/
.countdown { font-family: sans-serif; color: #ffffff; display: inline-block; font-weight: 100; text-align: center; font-size: 30px; }

.countdown__number { padding: 10px; border-radius: 3px; background: #ee751a; display: inline-block; }

.countdown__time { padding: 15px; border-radius: 3px; background: #000000; display: inline-block; }

.countdown__text { display: block; padding-top: 5px; font-size: 16px; }

/** COUNTDOWN END **/
/** CONTEST RESULT **/
.contest-result { display: none; position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 20; }

.contest-result:before { content: ''; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #707070; opacity: 0.9; }

.contest-result__content { position: absolute; top: 0; right: 0; bottom: 0; left: 0; max-width: 800px; max-height: 400px; margin: auto; border-radius: 8px; }

.contest-result__close { position: absolute; right: 16px; top: 8px; width: 24px; height: 24px; opacity: 0.5; }

.contest-result__close:hover { opacity: 1; cursor: pointer; }

.contest-result__close:before, .contest-result__close:after { position: absolute; left: 15px; content: ' '; height: 64px; width: 2px; background-color: #ee751a; }

.contest-result__close:before { transform: rotate(45deg); }

.contest-result__close:after { transform: rotate(-45deg); }

.contest-result__text { text-align: center; font-size: 18px; margin: 56px 16px 0; }

.contest-result__title { font-size: 64px; color: #ee751a; margin-bottom: 32px; }

.contest-result__winner { font-size: 64px; color: #ee751a; font-weight: 600; }

.contest-result__btn { margin-top: 32px; margin-left: auto; }

/** CONTEST RESULT END **/
