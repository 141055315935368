@import "normalize";
@import "fonts";
@import "vars";

@include normalize();

html, body {
  font-family: $robotoDark, sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: $whiteColor;
  background-color: $blackColor;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $orangeColor;
    border-radius: 8px;
  }
}
a {
  text-decoration: none;
  color: $blackColor;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}
ul {
  margin: 0;
  padding: 0;
}
.container {
  max-width: 1920px;
  padding: 0 16px;
  margin: auto;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 304px;
  height: 48px;
  color: $whiteColor;
  outline: none;
  border: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
.flex {
  display: flex;
  &_justify {
    &--center {
      justify-content: center;
    }
  }
  &_align {
    &--center {
      align-items: center;
    }
  }
}

/** FORM **/
.form {
  &__title {
    font-size: 32px;
    margin: 0;
  }
  &__body {
    margin-top: 32px;
  }
  &__group {
    position: relative;
    margin: 32px 0;
  }
  &__input, &__select {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    font-size: 16px;
    padding: 0 16px;
    outline: none;
    border: 1px solid #d6d8e0;
    border-radius: 2px;
  }
  &__textarea {
    box-sizing: border-box;
    width: 100%;
    height: 128px;
    font-size: 16px;
    border: 1px solid #d6d8e0;
    border-radius: 2px;
    padding: 16px;
    resize: none;
    &:focus {
      outline: none;
    }
  }
  &__label {
    display: block;
    color: $blackColor;
    margin-bottom: 8px;
  }
  &__errors {
    font-size: 12px;
    color: $redColor;
    margin: 8px 0 0 0;
  }
  &__error {
    display: none;
    &_type {
      &--validation {
        display: block;
        font-size: 16px;
      }
    }
  }
  &__button {
    margin: 40px 0 0 0;
  }
}
/** FORM END **/

/** FORM RESULT **/
.form-result {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $grayColor;
    opacity: 0.5;
  }
  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 400px;
    max-height: 239px;
    background-color: $whiteColor;
    color: $blackColor;
    margin: auto;
    border-radius: 8px;
    &:before {
      content: '';
      position: absolute;
      top: -1%;
      left: -1%;
      width: 101%;
      height: 101%;
      border: 2px solid $whiteColor;
      border-radius: 8px;
    }
  }
  &__close {
    position: absolute;
    right: 16px;
    top: 8px;
    width: 24px;
    height: 24px;
    opacity: 0.3;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: $blackColor;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__status {
    font-size: 20px;
    color: $blackColor;
    margin: 16px 0 0 16px;
  }
  &__text {
    text-align: center;
    font-size: 18px;
    margin: 56px 16px 0;
  }
}
/** FORM RESULT END **/

/** CHECKBOX LABEL **/
.checkbox-label {
  &__input {
    position: absolute;
    margin: 0;
    z-index: -1;
    opacity: 0;
    &:checked + .checkbox-label__link:before {
      background: $blueColor;
      border-color: $blueColor;
    }
  }
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 0 0 30px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      background: $whiteColor;
      border: 1px solid #dcdad5;
      border-radius: 2px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 6px;
      left: 6px;
      height: 6px;
      width: 11px;
      border-top: 2px solid $whiteColor;
      border-right: 2px solid $whiteColor;
      transform: rotate(135deg);
      transition: all 0.2s ease-out 0s;
      opacity: 1;
    }
  }
}
/** CHECKBOX LABEL END **/

/** FORM-RATICIPANT **/
.form-participant {
  position: relative;
  width: 100%;
  max-width: 800px;
  background: $whiteColor;
  margin: 144px auto;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 20px 2px $whiteColor;
  &.no-m {
    margin: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: -1%;
    left: -1%;
    width: 101%;
    height: 101%;
    border: 2px solid $whiteColor;
    border-radius: 8px;
  }
  &__title {
    color: $blackColor;
  }
  &__btn {
    position: relative;
    background-color: $blackColor;
  }
}
.password-form {
  & input {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    font-size: 16px;
    padding: 0 16px;
    outline: none;
    border: 1px solid #d6d8e0;
    border-radius: 2px;
  }
  & button {
    width: 100%;
    height: 48px;
    margin-top: 24px;
  }
}
/** FORM-RATICIPANT END **/

/** CONTEST-OPTIONS **/
.contest-options {
  display: none;
  &__hidden {
    display: none;
  }
}
/** CONTEST-OPTIONS END **/

/** REGISTRATION-SECTION **/
.registration-section {
  display: flex;
  justify-content: center;
  background: center/cover url('../images/bg1.png');
  min-height: 100vh;
  & .logo {
    display: flex;
    justify-content: center;
    padding: 40px;
    & img {
      max-width: 600px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
/** REGISTRATION-SECTION END **/

/** CONTEST-INFO-SECTION **/
.contest-info-section {
  background: center/cover url('../images/bg1.png');
  min-height: 100vh;
}
/** CONTEST-INFO-SECTION END **/

/** PARTICIPANTS **/
.participants {
  & .logo {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    & img {
      max-width: 600px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  & .participant {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 16px;
  }
  & .container {
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
  &__content {
    width: 100%;
    max-width: 800px;
    height: 464px;
    background-color: $whiteColor;
    border-radius: 8px;
    box-shadow: 0 0 20px 2px #fff;
    padding: 40px;
    z-index: 10;
  }
  &__form-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 600px;
  }
  &__title {
    font-size: 40px;
    color: $blackColor;
    margin-bottom: 32px;
  }
  &__info {
    color: $blackColor;
    min-height: 344px;
    max-height: 344px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $blackColor;
      border-radius: 8px;
    }
  }
  &__item {
    font-size: 23px;
    margin-bottom: 16px;
  }
  &__empty {
    font-size: 28px;
    text-transform: uppercase;
  }
}

@media (max-width: 1024px) {
  .contest-info-section {
    background: none;
  }

  .participants {
    padding: 16px;
    & .logo {
      display: flex;
      justify-content: center;
      padding: 24px 0;
      margin: 0 24px;
    }
    &__content {
      position: relative;
      top: auto;
      left: auto;
      max-width: 100%;
      padding: 24px;
    }
    &__title {
      font-size: 32px;
      text-align: center;
    }
  }
}
/** PARTICIPANTS END **/

/** CONTEST **/
.contest {
  position: absolute;
  width: 100%;
  height: 100%;
  &__content {
    position: absolute;
    right: 56px;
    bottom: 56px;
    top: auto;
    max-width: 800px;
    background-color: $whiteColor;
    border-radius: 8px;
    box-shadow: 0 0 20px 2px #fff;
    padding: 40px;
  }
  &__title {
    color: $blackColor;
    margin-bottom: 32px;
  }
  &__button {
    margin-top: 32px;
  }
  &__btn {
    display: none;
    background-color: $orangeColor;
    border-radius: 4px;
  }
}
/** CONTEST END **/

/** COUNTDOWN **/
.countdown {
  font-family: sans-serif;
  color: $whiteColor;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
  &__number {
    padding: 10px;
    border-radius: 3px;
    background: $orangeColor;
    display: inline-block;
  }
  &__time {
    padding: 15px;
    border-radius: 3px;
    background: $blackColor;
    display: inline-block;
  }
  &__text {
    display: block;
    padding-top: 5px;
    font-size: 16px;
  }
}
/** COUNTDOWN END **/

/** CONTEST RESULT **/
.contest-result {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $grayColor;
    opacity: 0.9;
  }
  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 800px;
    max-height: 400px;
    margin: auto;
    border-radius: 8px;
  }
  &__close {
    position: absolute;
    right: 16px;
    top: 8px;
    width: 24px;
    height: 24px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &:before, &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 64px;
      width: 2px;
      background-color: $orangeColor;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__text {
    text-align: center;
    font-size: 18px;
    margin: 56px 16px 0;
  }
  &__title {
    font-size: 64px;
    color: $orangeColor;
    margin-bottom: 32px;
  }
  &__winner {
    font-size: 64px;
    color: $orangeColor;
    font-weight: 600;
  }
  &__btn {
    margin-top: 32px;
    margin-left: auto;
  }
}
/** CONTEST RESULT END **/

